.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card:hover .overlay {
    opacity: 0;
  }
  
  .card-img-top {
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .card:hover .card-img-top {
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .custom-scrollbar {
    max-height: 200px;
    overflow-y: scroll;
  }
  
  /* Optional: Adjust the scrollbar appearance */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #9b3535;
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .custom-center {
    text-align: center;
  }